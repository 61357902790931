import React, { ReactElement, useEffect } from "react"
import { BasePageContext } from "../../../../interfaces/PageContext"
import { PageProps, navigate } from "gatsby"
import SEO from "../../../../components/SEO"
import useUserWithSignInRedirect from "../../../../hooks/useUserWithSignInRedirect"
import T from "@mui/material/Typography"
import PulseLoader from "../../../../components/PulseSpinner"
import Box from "@mui/material/Box"
import Button from "../../../../components/design-system/Button"

export const ImpersonateLoading = (): ReactElement => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <PulseLoader />
    </Box>
  )
}

const ImpersonateRedirect = ({
  newApplicationNumber,
}: {
  newApplicationNumber: string
}): ReactElement => {
  const { admin, applicationNumber } = useUserWithSignInRedirect()

  useEffect(() => {
    if (!admin.roles?.impersonation) return

    if (
      newApplicationNumber &&
      (newApplicationNumber !== applicationNumber ||
        !admin.status.isImpersonating)
    ) {
      admin.actions.impersonateApplication(newApplicationNumber)
      return
    }

    if (
      newApplicationNumber &&
      newApplicationNumber === applicationNumber &&
      admin.status.documents === "Fetched" &&
      admin.status.isImpersonating
    ) {
      navigate("/application", { replace: true })
      return
    }
  }, [admin, newApplicationNumber, applicationNumber])

  if (!admin.roles?.impersonation) {
    navigate("/404", { replace: true })
    return <></>
  }

  if (!newApplicationNumber) {
    return (
      <>
        <T variant="h1" component="h1">
          Application not found
        </T>
        <T variant="body1">
          {"You didn't provide an application number to impersonate."}
        </T>
      </>
    )
  }

  if (admin.status.documents === "Error") {
    return (
      <>
        <T variant="h1" component="h1">
          Error viewing application
        </T>
        <T variant="body1">
          There was a problem loading application {newApplicationNumber}.
        </T>
        <T variant="body1">
          {
            "It's likely the application number doesn't match an application in My Cambridge Application."
          }
        </T>
        <Button
          onClick={() => {
            admin.actions.stopImpersonation()
            navigate("/admin/applications")
          }}
          role="link"
        >
          View another application
        </Button>
      </>
    )
  }

  return <ImpersonateLoading />
}

const ImpersonateRedirectPage = ({
  params,
}: PageProps<never, BasePageContext>): ReactElement => {
  const applicationNumber = params.id

  return (
    <>
      <SEO title="Impersonate an applicant" />
      <ImpersonateRedirect newApplicationNumber={applicationNumber} />
    </>
  )
}

export default ImpersonateRedirectPage
