import React, { FC } from "react"
import Box from "@mui/material/Box"
import { keyframes, styled } from "@mui/material/styles"

// TODO this could be a property
const SPEED_MULTIPLIER = 0.7

const Pulse = styled("span")(({ theme }) => {
  const bounce = keyframes`
    0% {
      background-color: ${theme.palette.primary.dark};
    }
    50% {
      background-color: ${theme.palette.primary.light};
    }
    100% {
      background-color: ${theme.palette.primary.dark};
    }
  `
  return {
    width: 15,
    height: 15,
    marginRight: theme.spacing(3),
    borderRadius: "100%",
    display: "inline-block",
    animation: `${bounce} ${0.75 / SPEED_MULTIPLIER}s infinite linear`,
    animationFillMode: "both",
  }
})

const PulseSpinner: FC = () => (
  <Box>
    {[1, 2, 3, 4].map(i => (
      <Pulse
        key={i}
        style={{ animationDelay: `${(i * 0.12) / SPEED_MULTIPLIER}s` }}
      />
    ))}
  </Box>
)

export default PulseSpinner
